import Wallet, {
  AddressPurpose,
  BitcoinNetworkType,
} from "sats-connect";
import { useContext, useEffect, useRef, useState } from "react";
import { truncateAddress } from "../../utils/common";
import { IoChevronDown } from "react-icons/io5";
import { GiHamburgerMenu } from "react-icons/gi";
import { NavLink, useLocation } from "react-router-dom";
import { CmsContext } from "../../context/CmsContext";
import { useDispatch, useSelector } from "react-redux";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import {
  setPointsData,
  setPointsIncrement,
  setRunePoints,
} from "../../services/GlobalSlice";
import { FaTelegramPlane } from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";
import { useGetPointsQuery } from "../../services/runesApi";

function Navbar() {
  const cmsData = useContext(CmsContext);
  const points = useSelector((state: any) => state.global.points);
  const pointsIncrement = useSelector(
    (state: any) => state.global.pointsIncrement
  );

  const modalRef = useOutsideClick(() => setOpen(false));
  const toolModalRef = useOutsideClick(() => setShowModal(false));

  const openModalRef = useOutsideClick(() => setOpenModal(false));

  const [openModal, setOpenModal] = useState<boolean>(false);

  const { walletAddress, setWalletAddress, addressInfo, setAddressInfo }: any =
    useContext(CmsContext);

  const [smallDisconnect, setSmallDisconnect] = useState<boolean>(false);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [showMenu, setShowMenu] = useState<boolean>(false);

  // const [walletAddress, setWalletAddress] = useState<Address[]>([]);
  const [open, setOpen] = useState<boolean>(false);

  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const runePoints = useSelector((state: any) => state.global.runePoints);
  const dispatch = useDispatch();
  const { data: pointApiData } = useGetPointsQuery(
    {
      address: localStorage.getItem("ordinalAddress"),
    },
    { skip: walletAddress.length === 0 }
  );

  const onConnect = async () => {

    const response = await Wallet.request("getAccounts", {
      purposes: [
        AddressPurpose.Payment,
        AddressPurpose.Ordinals,
        AddressPurpose.Stacks,
      ],
      message: "Cool app wants to know your addresses!",
    });
    // console.log(response, "res");
    if (response.status === "success") {
      setAddressInfo(response.result);
      localStorage.setItem("ordinalAddress", response.result[1].address);
    }
  };

  const onDisconnect = () => {
    // console.log("i am in disconnect")
    Wallet.disconnect();
    setAddressInfo([]);
    setWalletAddress([]);
    localStorage.removeItem("ordinalAddress");
  };

  const handleToggle = () => {
    setOpen((prevState) => !prevState);
    setSmallDisconnect((prevState) => !prevState);
  };

  useEffect(() => {
    if (pointApiData) {
      dispatch(setPointsData(pointApiData));
    }
  }, [dispatch, pointApiData]);

  useEffect(() => {
    dispatch(setRunePoints(points?.points));
    dispatch(setPointsIncrement(points?.pointsIncrement));
  }, [dispatch, points]);

  useEffect(() => {
    if (typeof pointsIncrement === "boolean") {
      if (runePoints && pointsIncrement === true) {
        intervalRef.current = setInterval(() => {
          dispatch(setRunePoints(runePoints + 1));
        }, 1000);
      }

      return () => {
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
      };
    }
  }, [runePoints, pointsIncrement, dispatch]);

  let location = useLocation();

  return (
    <div className="  w-full fixed border-y  border-[#202026]  bg-black/40 backdrop-blur-sm text-[12px] sm:text-[14px] z-[2]">
      <div className=" w-full  bg-#0F0F14   border-y border-[#202026] backdrop-blur-sm z-[3]  flex justify-center top-0 p-[1.5px] px-4">
        <p className="  text-[16px] text-transparent  font-monsterate text-wrap font-bold bg-custom-gradient bg-clip-text ">
          {cmsData?.cmsText?.warning}
        </p>
      </div>
      <div className=" flex justify-between px-4 py-3">
        <NavLink to={"/home"}>
          <img
            className="cursor-pointer w-[120px] sm:w-[150px]"
            src={"/RuneLogo.svg"}
            alt={"logo"}
          />
        </NavLink>
        <div className="hidden mdx:flex justify-end gap-10 items-center ">
          {addressInfo?.length > 0 ? (
            <>
              <div className=" flex gap-8 items-center  ">
                <div
                  className={`flex gap-1 items-center cursor-pointer border px-3 py-1 text-nowrap ${
                    location.pathname === "/points"
                      ? "bg-[#ACEC6D] text-black"
                      : "bg-[#0A0A0E] text-white "
                  }  border-[#ACEC6D] rounded-md`}
                >
                  {location.pathname === "/points" ? (
                    <img
                      src="/star.svg"
                      alt="points"
                      className=" cursor-pointer "
                      width={20}
                      height={20}
                    />
                  ) : (
                    <img
                      src="/greenStar.svg"
                      alt="points"
                      className=" cursor-pointer "
                      width={20}
                      height={20}
                    />
                  )}
                  <NavLink to={"/points"}>
                    <h1 className=" text-[14px] leading-[25px] font-monsterate  font-bold">
                      {walletAddress.length !== 0 && runePoints
                        ? Number(runePoints).toLocaleString()
                        : 0}{" "}
                      {cmsData?.cmsText?.points}
                    </h1>
                  </NavLink>
                </div>
                <NavLink to={"/explore"}>
                  <h1 className=" text-[15px] font-monsterate leading-[28px] text-[#FFFFFF] cursor-pointer border-b-2 border-transparent active:border-[#FFE564] hover:border-[#FFE564]">
                    {cmsData?.cmsText?.explore}
                  </h1>
                </NavLink>
                {/* <h1 className=" text-[15px] font-monsterate leading-[28px] text-[#FFFFFF] cursor-pointer border-b-2 border-transparent active:border-[#FFE564] hover:border-[#FFE564]">
                  {cmsData?.cmsText?.myStuff}
                </h1> */}
                <div ref={toolModalRef}>
                  <div
                    onClick={() => setShowModal(!showModal)}
                    className=" relative flex gap-1 cursor-pointer border-b-2 border-transparent active:border-[#FFE564] hover:border-[#FFE564]"
                  >
                    <h1 className=" text-[15px] font-monsterate leading-[28px] text-[#FFFFFF] ">
                      {cmsData?.cmsText?.tools}
                    </h1>
                    <img
                      src="/dropdown.svg"
                      alt="dropdown"
                      className=" cursor-pointer"
                      width={9}
                      height={5}
                    />
                  </div>
                  {showModal && (
                    <div className="absolute w-[750px] top-[95px] right-28 bg-[#191920] px-8 py-6 rounded-2xl flex justify-between items-center">
                      <div className=" flex flex-col gap-2">
                        <img
                          src="/explorer.png"
                          alt="options"
                          className=" w-[100px] h-[103px] p-4 rounded-3xl bg-[#0F0F14] mx-auto"
                          width={67}
                          height={74}
                        />
                        <p className=" font-permanentMarker text-white text-center  text-[24px] leading-[26px]">
                          {cmsData?.cmsText?.explorer}
                        </p>
                        <span className=" font-monsterate text-[#7A7A8E] text-[15px] leading-[27px] font-medium">
                          {cmsData?.cmsText?.exploreRunes}
                        </span>
                        <NavLink className="flex items-center" to={"/explore"}>
                          <button
                            type="button"
                            className=" font-monsterate w-[70%] mx-auto bg-custom-gradient font-bold py-2 px-5 rounded-3xl"
                          >
                            {cmsData?.cmsText?.startBtn}
                          </button>
                        </NavLink>
                      </div>
                      <div className=" flex flex-col gap-2">
                        <img
                          src="/minter.png"
                          alt="options"
                          className=" w-[100px] h-[103px] p-4 rounded-3xl bg-[#0F0F14] mx-auto"
                          width={67}
                          height={74}
                        />
                        <p className=" font-permanentMarker text-white text-center  text-[24px] leading-[26px]">
                          {cmsData?.cmsText?.minter}
                        </p>
                        <span className=" font-monsterate text-[#7A7A8E] text-[15px] leading-[27px] font-medium">
                          {cmsData?.cmsText?.mintRunesToken}
                        </span>
                        {/* <NavLink className="flex items-center" to={"/explore"}>
                          <button className=" font-monsterate w-[70%] mx-auto bg-custom-gradient py-2 px-5 rounded-3xl">
                            Start
                          </button>
                        </NavLink> */}
                        <p className=" font-monsterate w-[70%] mx-auto py-2 text-transparent font-bold px-5 bg-custom-gradient bg-clip-text ">
                          {cmsData?.cmsText?.soon}
                        </p>
                      </div>

                      <div className=" flex flex-col gap-2">
                        <img
                          src="/runeDrop.png"
                          alt="options"
                          className=" w-[100px] h-[103px] p-4 rounded-3xl bg-[#0F0F14] mx-auto"
                          width={67}
                          height={74}
                        />
                        <p className=" font-permanentMarker text-white text-center  text-[24px] leading-[26px]">
                          {cmsData?.cmsText?.runeDrop}
                        </p>
                        <span className=" font-monsterate text-[#7A7A8E] text-[15px] leading-[27px] font-medium">
                          {cmsData?.cmsText?.airdropTool}
                        </span>
                        <p className=" font-monsterate w-[70%] mx-auto text-transparent font-bold py-2 px-5 bg-custom-gradient bg-clip-text">
                          {cmsData?.cmsText?.soon}
                        </p>
                      </div>

                      <div className=" flex flex-col gap-2">
                        <img
                          src="/runeBot.png"
                          alt="options"
                          className=" w-[100px] h-[103px] p-4 rounded-3xl bg-[#0F0F14] mx-auto"
                          width={67}
                          height={74}
                        />
                        <p className=" font-permanentMarker text-white text-center  text-[24px] leading-[26px]">
                          {cmsData?.cmsText?.runeBot}
                        </p>
                        <span className=" font-monsterate text-[#7A7A8E] text-[15px] leading-[27px] font-medium">
                          {cmsData?.cmsText?.transactRunes}
                        </span>
                        <p className=" font-monsterate w-[70%] mx-auto py-2 text-transparent font-bold px-5 bg-custom-gradient bg-clip-text ">
                          {cmsData?.cmsText?.soon}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className=" flex items-center gap-5">
                <a
                  href={cmsData?.cmsText?.telegramLink || ""}
                  target="_blank"
                  rel="noreferrer"
                  className=" rounded-full flex justify-center items-center bg-custom-gradient p-2"
                >
                  <img
                    src="/telegram.svg"
                    alt="telegram"
                    className=" cursor-pointer text-white"
                    width={20}
                    height={20}
                  />
                </a>
                <a
                  href={cmsData?.cmsText?.twitterLink || ""}
                  target="_blank"
                  rel="noreferrer"
                  className=" rounded-full flex justify-center items-center bg-custom-gradient p-2"
                >
                  <img
                    src="/twitter.svg"
                    alt="twitter"
                    className=" cursor-pointer text-white "
                    width={20}
                    height={20}
                  />
                </a>
              </div>
            </>
          ) : (
            <>
              <div className=" flex gap-8 items-center  ">
                <div
                  className={`flex gap-1 items-center cursor-pointer border px-3 pr-8 py-1 text-nowrap ${
                    location.pathname === "/points"
                      ? "bg-[#ACEC6D] text-black"
                      : "bg-[#0A0A0E] text-white "
                  }  border-[#ACEC6D] rounded-md`}
                >
                  {location.pathname === "/points" ? (
                    <img
                      src="/star.svg"
                      alt="points"
                      className=" cursor-pointer "
                      width={20}
                      height={20}
                    />
                  ) : (
                    <img
                      src="/greenStar.svg"
                      alt="points"
                      className=" cursor-pointer "
                      width={20}
                      height={20}
                    />
                  )}
                  <NavLink to={"/points"}>
                    <h1 className=" text-[14px] leading-[25px] font-monsterate  font-bold">
                      {walletAddress.length !== 0 && runePoints
                        ? Number(runePoints).toLocaleString()
                        : 0}
                      {cmsData?.cmsText?.points}
                    </h1>
                  </NavLink>
                </div>
                <NavLink to={"/explore"}>
                  <h1 className=" text-[15px] font-monsterate leading-[28px] text-[#FFFFFF] cursor-pointer border-b-2 border-transparent active:border-[#FFE564] hover:border-[#FFE564]">
                    {cmsData?.cmsText?.explore}
                  </h1>
                </NavLink>
                {/* <h1 className=" text-[15px] font-monsterate leading-[28px] text-[#FFFFFF] cursor-pointer border-b-2 border-transparent active:border-[#FFE564] hover:border-[#FFE564]">
                  {cmsData?.cmsText?.myStuff}
                </h1> */}
                <div ref={toolModalRef}>
                  <div
                    onClick={() => setShowModal(!showModal)}
                    className=" relative flex gap-1 cursor-pointer border-b-2 border-transparent active:border-[#FFE564] hover:border-[#FFE564]"
                  >
                    <h1 className=" text-[15px] font-monsterate leading-[28px] text-[#FFFFFF] ">
                      {cmsData?.cmsText?.tools}
                    </h1>
                    <img
                      src="/dropdown.svg"
                      alt="dropdown"
                      className=" cursor-pointer"
                      width={9}
                      height={5}
                    />
                  </div>
                  {showModal && (
                    <div className="absolute w-[750px] top-[95px] right-28 bg-[#191920] px-8 py-6 rounded-2xl flex justify-between items-center">
                      <div className=" flex flex-col gap-2">
                        <img
                          src="/explorer.png"
                          alt="options"
                          className=" w-[100px] h-[103px] p-4 rounded-3xl bg-[#0F0F14] mx-auto"
                          width={67}
                          height={74}
                        />
                        <p className=" font-permanentMarker text-white text-center  text-[24px] leading-[26px]">
                          {cmsData?.cmsText?.explorer}
                        </p>
                        <span className=" font-monsterate text-[#7A7A8E] text-[15px] leading-[27px] font-medium">
                          {cmsData?.cmsText?.exploreRunes}
                        </span>
                        <NavLink className="flex items-center" to={"/explore"}>
                          <button
                            type="button"
                            className=" font-monsterate w-[70%] mx-auto bg-custom-gradient font-bold py-2 px-5 rounded-3xl"
                          >
                            {cmsData?.cmsText?.startBtn}
                          </button>
                        </NavLink>
                      </div>
                      <div className=" flex flex-col gap-2">
                        <img
                          src="/minter.png"
                          alt="options"
                          className=" w-[100px] h-[103px] p-4 rounded-3xl bg-[#0F0F14] mx-auto"
                          width={67}
                          height={74}
                        />
                        <p className=" font-permanentMarker text-white text-center  text-[24px] leading-[26px]">
                          {cmsData?.cmsText?.minter}
                        </p>
                        <span className=" font-monsterate text-[#7A7A8E] text-[15px] leading-[27px] font-medium">
                          {cmsData?.cmsText?.mintRunesToken}
                        </span>
                        {/* <NavLink className="flex items-center" to={"/explore"}>
                          <button className=" font-monsterate w-[70%] mx-auto bg-custom-gradient py-2 px-5 rounded-3xl">
                            Start
                          </button>
                        </NavLink> */}
                        <p className=" font-monsterate w-[70%] mx-auto py-2 text-transparent font-bold px-5 bg-custom-gradient bg-clip-text ">
                          {cmsData?.cmsText?.soon}
                        </p>
                      </div>

                      <div className=" flex flex-col gap-2">
                        <img
                          src="/runeDrop.png"
                          alt="options"
                          className=" w-[100px] h-[103px] p-4 rounded-3xl bg-[#0F0F14] mx-auto"
                          width={67}
                          height={74}
                        />
                        <p className=" font-permanentMarker text-white text-center  text-[24px] leading-[26px]">
                          {cmsData?.cmsText?.runeDrop}
                        </p>
                        <span className=" font-monsterate text-[#7A7A8E] text-[15px] leading-[27px] font-medium">
                          {cmsData?.cmsText?.airdropTool}
                        </span>
                        <p className=" font-monsterate w-[70%] mx-auto text-transparent font-bold py-2 px-5 bg-custom-gradient bg-clip-text">
                          {cmsData?.cmsText?.soon}
                        </p>
                      </div>

                      <div className=" flex flex-col gap-2">
                        <img
                          src="/runeBot.png"
                          alt="options"
                          className=" w-[100px] h-[103px] p-4 rounded-3xl bg-[#0F0F14] mx-auto"
                          width={67}
                          height={74}
                        />
                        <p className=" font-permanentMarker text-white text-center  text-[24px] leading-[26px]">
                          {cmsData?.cmsText?.runeBot}
                        </p>
                        <span className=" font-monsterate text-[#7A7A8E] text-[15px] leading-[27px] font-medium">
                          {cmsData?.cmsText?.transactRunes}
                        </span>
                        <p className=" font-monsterate w-[70%] mx-auto py-2 text-transparent font-bold px-5 bg-custom-gradient bg-clip-text ">
                          {cmsData?.cmsText?.soon}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className=" mr-10 flex items-center gap-5">
                <a
                  title="telegram"
                  href={cmsData?.cmsText?.telegramLink || ""}
                  target="_blank"
                  rel="noreferrer"
                  className=" rounded-full flex justify-center items-center bg-custom-gradient p-2"
                >
                  <FaTelegramPlane className=" text-[15px] text-black cursor-pointer" />
                </a>
                <a
                  title="twitter"
                  href={cmsData?.cmsText?.twitterLink || ""}
                  target="_blank"
                  rel="noreferrer"
                  className=" rounded-full flex justify-center items-center bg-custom-gradient p-2"
                >
                  <BsTwitterX className=" text-[15px] text-black cursor-pointer" />
                </a>
              </div>
            </>
          )}
          {addressInfo?.length > 0 ? (
            <div ref={modalRef} className="relative">
              <button
                type="button"
                className="w-full gradient-border font-monsterate flex justify-between items-center gap-2 sm:gap-5 font-bold px-2 sm:px-6  py-1 sm:py-2 text-white"
                onClick={handleToggle}
              >
                {truncateAddress(walletAddress?.[0]?.address)}
                <IoChevronDown />
              </button>
              {open && (
                <div className="w-full absolute flex flex-col items-center gap-2 text-white bg-black/40 backdrop-blur-[5px] z-[40]  gradient-border mt-2 px-6 py-4 right-[0px]">
                  <div
                    className="whitespace-nowrap cursor-pointer font-bold"
                    onClick={cmsData?.setNetwork(BitcoinNetworkType.Mainnet)}
                  >
                    {cmsData?.network}
                  </div>
                  <div className="w-full h-px bg-white/30"></div>
                  <div
                    className="whitespace-nowrap font-monsterate cursor-pointer font-bold"
                    onClick={() => {
                      onDisconnect();
                      setOpen(false);
                    }}
                  >
                    {cmsData?.cmsText?.disconnect}
                  </div>
                </div>
              )}
            </div>
          ) : (
            <button
              type="button"
              className="w-full gradient-border font-monsterate flex justify-between items-center gap-2 sm:gap-5 font-bold px-2 sm:px-6  py-1 sm:py-2 text-white"
              onClick={onConnect}
            >
              {cmsData?.cmsText?.connectButton}
            </button>
          )}
        </div>

        <div ref={openModalRef} className=" flex relative mdx:hidden">
          <div className=" mr-10 flex items-center gap-5">
            <a
              href={cmsData?.cmsText?.telegramLink || ""}
              target="_blank"
              rel="noreferrer"
              className=" rounded-full flex justify-center items-center bg-custom-gradient p-2"
            >
              <img
                src="/telegram.svg"
                alt="telegram"
                className=" cursor-pointer text-white"
                width={15}
                height={15}
              />
            </a>
            <a
              href={cmsData?.cmsText?.twitterLink || ""}
              target="_blank"
              rel="noreferrer"
              className=" rounded-full flex justify-center items-center bg-custom-gradient p-2"
            >
              <img
                src="/twitter.svg"
                alt="twitter"
                className=" cursor-pointer text-white "
                width={15}
                height={15}
              />
            </a>
          </div>
          <button
            type="button"
            onClick={() => setOpenModal((prev) => !prev)}
            className=" block mdx:hidden text-white/60 text-xl right-0"
          >
            <GiHamburgerMenu className="text-white " />
            {""}
          </button>
          {openModal && (
            <div
              className={` w-[210px]  absolute top-[45px] right-1  border gradient-border rounded-xl items-center  mdx:hidden  bg-black/80 backdrop-blur-[10px]  py-6 flex flex-col gap-3  px-3 z-[40]`}
            >
              {addressInfo?.length > 0 ? (
                <div className="">
                  <button
                    type="button"
                    className="w-full flex justify-between font-monsterate gap-2 sm:gap-5 font-bold px-2 sm:px-6  py-1 sm:py-2 text-white"
                    onClick={handleToggle}
                  >
                    {truncateAddress(walletAddress?.[0]?.address)}
                    <IoChevronDown />
                  </button>
                  {smallDisconnect && (
                    <div className="w-full flex flex-col items-center gap-3 text-white bg-black  px-6 py-4 right-[0px]">
                      <div
                        className="whitespace-nowrap cursor-pointer font-bold"
                        onClick={cmsData?.setNetwork(
                          BitcoinNetworkType.Mainnet
                        )}
                      >
                        {cmsData?.network}
                      </div>
                      <div className="w-full h-px bg-white/30"></div>
                      <div
                        className="whitespace-nowrap font-monsterate cursor-pointer font-bold"
                        onClick={() => {
                          onDisconnect();
                          setOpenModal(false);
                          setSmallDisconnect(false);
                        }}
                      >
                        {cmsData?.cmsText?.disconnect}
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <button
                  type="button"
                  className="w-full  flex justify-center font-monsterate text-[15px] leading-[28px]  font-bold  text-white"
                  onClick={onConnect}
                >
                  {cmsData?.cmsText?.connectButton}
                </button>
              )}
              {/* <div className=" flex gap-1 items-center cursor-pointer border px-3 py-1 bg-[#ACEC6D] border-[#ACEC6D] rounded-md">
                <img
                  src="/star.svg"
                  alt="points"
                  className=" cursor-pointer "
                  width={20}
                  height={20}
                />
                <NavLink to={"/points"}>
                  <h1 className=" text-[14px] font-monsterate leading-[25px] text-nowrap  font-bold">
                    {walletAddress.length !== 0 && runePoints ? runePoints : 0}
                    {cmsData?.cmsText?.points}
                  </h1>
                </NavLink>
              </div> */}
              <div
                className={`flex gap-1 items-center cursor-pointer border px-3 pr-8 py-1 text-nowrap ${
                  location.pathname === "/points"
                    ? "bg-[#ACEC6D] text-black"
                    : "bg-transparent text-white "
                }  border-[#ACEC6D] rounded-md`}
              >
                {location.pathname === "/points" ? (
                  <img
                    src="/star.svg"
                    alt="points"
                    className=" cursor-pointer "
                    width={20}
                    height={20}
                  />
                ) : (
                  <img
                    src="/greenStar.svg"
                    alt="points"
                    className=" cursor-pointer "
                    width={20}
                    height={20}
                  />
                )}
                <NavLink to={"/points"}>
                  <h1 className=" text-[14px] leading-[25px] font-monsterate  font-bold">
                    {walletAddress.length !== 0 && runePoints ? runePoints : 0}{" "}
                    {cmsData?.cmsText?.points}
                  </h1>
                </NavLink>
              </div>
              <h1 className=" text-[15px] font-monsterate leading-[28px] text-[#FFFFFF] cursor-pointer border-b-2 border-transparent active:border-[#FFE564] hover:border-[#FFE564]">
                <NavLink to={"/explore"}>{cmsData?.cmsText?.explore}</NavLink>
              </h1>
              {/* <h1 className=" text-[15px] font-monsterate leading-[28px] text-[#FFFFFF] cursor-pointer border-b-2 border-transparent active:border-[#FFE564] hover:border-[#FFE564]">
                {cmsData?.cmsText?.myStuff}
              </h1> */}
              <div
                onClick={() => setShowMenu(!showMenu)}
                className=" flex gap-1 border-b-2 border-transparent active:border-[#FFE564] hover:border-[#FFE564]"
              >
                <h1 className=" text-[15px] font-monsterate leading-[28px] text-[#FFFFFF] cursor-pointer ">
                  {cmsData?.cmsText?.tools}
                </h1>
                <img
                  src="/dropdown.svg"
                  alt="dropdown"
                  className=" cursor-pointer"
                  width={9}
                  height={5}
                />
              </div>
              {showMenu && (
                <div className="w-full px-4 py-4 rounded-2xl flex flex-col gap-3 backdrop-blur-[10px] mt-[-20px]   ">
                  <div className=" flex  justify-between items-center py-2 border-b-2 border-[#4F4F5D]">
                    <div className=" flex flex-col gap-[3px] items-start">
                      <p className=" font-permanentMarker text-white text-start  text-[20px] leading-[18px]">
                        {cmsData?.cmsText?.explorer}
                      </p>
                      <span className=" font-monsterate text-[#7A7A8E] text-[12px] leading-[16px] font-medium">
                        {cmsData?.cmsText?.exploreRunes}
                      </span>
                      <NavLink to={"/explore"}>
                        <button
                          type="button"
                          className="w-fit font-monsterate  bg-custom-gradient font-bold py-1 px-2 rounded-md"
                        >
                          {cmsData?.cmsText?.startBtn}
                        </button>
                      </NavLink>
                    </div>
                    <img
                      src="/explorer.png"
                      alt="options"
                      className=" w-[50px] h-[63px] p-2 rounded-lg object-contain bg-[#0F0F14]"
                      width={67}
                      height={74}
                    />
                  </div>
                  <div className=" flex  justify-between items-center py-2 border-b-2 border-[#4F4F5D]">
                    <div className=" flex flex-col gap-[3px] items-start">
                      <p className=" font-permanentMarker text-white text-start  text-[20px] leading-[18px]">
                        {cmsData?.cmsText?.minter}
                      </p>
                      <span className=" font-monsterate text-[#7A7A8E] text-[12px] leading-[16px] font-medium">
                        {cmsData?.cmsText?.mintRunesToken}
                      </span>
                      {/* <NavLink className="flex justify-start" to={"/explore"}>
                        <button
                          type="button"
                          className="w-fit font-monsterate bg-custom-gradient py-1 px-2 rounded-md"
                        >
                          Start
                        </button>
                      </NavLink> */}
                      <p className=" font-monsterate w-full mx-auto py-2 font-bold text-transparent px-2 bg-custom-gradient bg-clip-text ">
                        {cmsData?.cmsText?.soon}
                      </p>
                    </div>
                    <img
                      src="/minter.png"
                      alt="options"
                      className=" w-[50px] h-[63px] p-2 rounded-lg object-contain  bg-[#0F0F14]"
                      width={67}
                      height={74}
                    />
                  </div>

                  <div className=" flex  justify-between items-center py-2 border-b-2 border-[#4F4F5D]">
                    <div className=" flex flex-col gap-[3px] items-start">
                      <p className=" font-permanentMarker text-white text-start  text-[20px] leading-[18px]">
                        {cmsData?.cmsText?.runeDrop}
                      </p>
                      <span className=" font-monsterate text-[#7A7A8E] text-[12px] leading-[16px] font-medium">
                        {cmsData?.cmsText?.airdropTool}
                      </span>
                      <p className=" font-monsterate w-full mx-auto text-transparent font-bold py-1 px-2 bg-custom-gradient bg-clip-text">
                        {cmsData?.cmsText?.soon}
                      </p>
                    </div>
                    <img
                      src="/runeDrop.png"
                      alt="options"
                      className=" w-[50px] h-[63px] p-2 rounded-lg object-contain  bg-[#0F0F14]"
                      width={67}
                      height={74}
                    />
                  </div>

                  <div className=" flex  justify-between items-center py-2 ">
                    <div className=" flex flex-col gap-[3px] items-start">
                      <p className=" font-permanentMarker text-white text-start  text-[20px] leading-[18px]">
                        {cmsData?.cmsText?.runeBot}
                      </p>
                      <span className=" font-monsterate text-[#7A7A8E] text-[12px] leading-[16px] font-medium">
                        {cmsData?.cmsText?.transactRunes}
                      </span>
                      <p className=" font-monsterate w-full text-transparent font-bold py-1 px-2 bg-custom-gradient bg-clip-text">
                        {cmsData?.cmsText?.soon}
                      </p>
                    </div>
                    <img
                      src="/runeBot.png"
                      alt="options"
                      className=" w-[50px] h-[63px] p-2 rounded-lg object-contain  bg-[#0F0F14]"
                      width={67}
                      height={74}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Navbar;
