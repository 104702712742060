import React, { useContext, useEffect, useRef, useState } from "react";
// import { Select } from "antd";
import { CmsContext } from "../../../../context/CmsContext";
import Card from "./components/Card";
import MyRunes from "./components/MyRunes";
import { Token } from "../../../../utils/types";
import {
  useGetMyRunesQuery,
  useGetRunesCollectionStatsQuery,
} from "../../../../services/runesApi";
import { useDispatch, useSelector } from "react-redux";
import {
  setChartInitial,
  setMyRunesData,
  setRunesData,
} from "../../../../services/GlobalSlice";
import { useDebounce } from "../../../../hooks/useDebounce";
import { PiSpinner } from "react-icons/pi";

interface SidebarProps {
  open: boolean;
  setOpen: any;
  searchValue: string;
  setSearchValue: any;
}

const Sidebar: React.FC<SidebarProps> = ({
  open,
  setOpen,
  searchValue,
  setSearchValue,
}) => {
  const [step, setStep] = useState<number>(1);
  const exploreData = useContext(CmsContext);
  const cardRef: any = useRef();
  const myRuneRef: any = useRef();
  const { walletAddress, exploreText }: any = useContext(CmsContext);

  const [runeSearchValue, setRuneSearchValue] = useState<string>("");

  // rtk
  const dispatch = useDispatch();
  const [runeOffset, setRuneOffset] = useState<number>(0);
  const { data: myRunesDataFromApi, isFetching: myRuneApiFetching } =
    useGetMyRunesQuery(
      {
        address: localStorage.getItem("ordinalAddress"),
      },
      {
        skip: walletAddress?.length === 0,
      }
    );

  const debouncedSearch = useDebounce(searchValue, 1000);
  const { data: runeDataFromApi, isFetching: runeApiFetching } =
    useGetRunesCollectionStatsQuery({
      offset: runeOffset,
      search: debouncedSearch,
    });

  const runeData = useSelector((state: any) => state.global.tokens || []);
  const myRunesData = useSelector((state: any) => state.global.myRunes || []);

  const debouncedMyRunesSearch = useDebounce(runeSearchValue, 1000);

  //Wallet address logic

  useEffect(() => {
    if (runeDataFromApi) {
      dispatch(setRunesData(runeDataFromApi.runes));
    }
    if(runeDataFromApi && runeOffset === 0){
      dispatch(setChartInitial(runeDataFromApi.runes[0]));
    }
  }, [runeDataFromApi, debouncedSearch, dispatch, runeOffset]);

  useEffect(() => {
    if (!myRunesDataFromApi) return;
  
    // Filter runes based on search
    let myRunes = debouncedMyRunesSearch
      ? myRunesDataFromApi.filter((item: Token) =>
          item.rune.toLowerCase().includes(debouncedMyRunesSearch.toLowerCase())
        )
      : [...myRunesDataFromApi];
  
    // Move ABCRUNESTOKEN to the first position if it's found and not already first
    const abcRuneIndex = myRunes.findIndex((item: any) => item.rune === "ABCRUNESTOKEN");
    if (abcRuneIndex > 0) {
      [myRunes[0], myRunes[abcRuneIndex]] = [myRunes[abcRuneIndex], myRunes[0]];
    }
  
    dispatch(setMyRunesData(myRunes));
  }, [myRunesDataFromApi, debouncedMyRunesSearch, dispatch]);

  const handleCardScroll = () => {
    const container = cardRef?.current as HTMLDivElement;
    const { scrollTop, scrollHeight, clientHeight } = container;
    if (Math.floor(scrollTop + clientHeight) >= scrollHeight - 5) {
      setRuneOffset((prevOffset) => prevOffset + 10);
    }
  };

  // const handleMyRuneScroll = () => {
  //   const container = myRuneRef?.current as HTMLDivElement;
  //   const { scrollTop, scrollHeight, clientHeight } = container;
  //   if (Math.floor(scrollTop + clientHeight) >= scrollHeight - 5) {
  //     setMyRuneOffset((prevOffset) => prevOffset + 10);
  //   }
  // };

  useEffect(() => {
    if (step === 1) {
      const container = cardRef?.current;
      if (container) {
        container.addEventListener("scroll", handleCardScroll);
        return () => {
          container.removeEventListener("scroll", handleCardScroll);
        };
      }
    }
    // if (step === 2) {
    //   const runeContainer = myRuneRef?.current;
    //   if (runeContainer) {
    //     runeContainer.addEventListener("scroll", handleMyRuneScroll);
    //     return () => {
    //       runeContainer.removeEventListener("scroll", handleMyRuneScroll);
    //     };
    //   }
    // }
  }, [step]);

  // console.log(myRunesData,"myRunesData")

  return (
    <div
      className={` ${
        open ? "block" : "hidden"
      } lg:block   absolute  lg:relative h-full xs:h-[calc(100vh-180px)]  overflow-hidden  sm:z-0 bg-black/40 backdrop-blur-[10px]`}
    >
      <div className="w-[300px] xl:w-[350px] 2xl:w-[370px] py-7  gap-10 border-r-2 border-[#202026] ">
        <div className=" flex flex-col gap-10">
          <div className="  flex flex-wrap mx-auto">
            <button
              type="button"
              className={` py-2 px-5 xl:px-7 font-monsterate  rounded font-bold ${
                step === 1
                  ? "bg-custom-gradient"
                  : "text-sm text-[#7A7A8E] bg-[#0F0F14] leading-[25px]"
              }  `}
              onClick={() => setStep(1)}
            >
              {exploreData?.exploreText?.allRunes}
            </button>
            <button
              type="button"
              onClick={() => setStep(2)}
              className={` py-2 px-7 rounded font-monsterate font-bold ${
                step === 2
                  ? "bg-custom-gradient"
                  : "text-sm text-[#7A7A8E] bg-[#0F0F14] leading-[25px]"
              }  `}
            >
              {exploreData?.exploreText?.myRunes}
            </button>{" "}
          </div>
          {step === 1 ? (
            <div className=" flex flex-col gap-7 ">
              <div className=" flex flex-col gap-3 px-6 xl:px-10">
                {/* <div className=" w-full flex justify-between">
                  <div className=" flex cursor-pointer gap-2 relative ">
                    <Select
                      defaultValue="popular"
                      style={{ color: "white" }}
                      onChange={handleChange}
                      options={[
                        { value: "popular", label: "Popular" },
                        { value: "trending", label: "Trending" },
                        { value: "new", label: "New" },
                      ]}
                    />
                    <img
                      src="/dropdown.svg"
                      alt="dropdown"
                      className=" absolute right-2 top-4"
                      width={9}
                      height={5}
                    />
                  </div>
                  <img
                    src="/options.svg"
                    alt="options"
                    width={26}
                    height={26}
                  />
                </div> */}

                <div className=" w-full h-[50px] px-3 border-2 gradient-border  flex items-center rounded-xl">
                  <img src="/search.svg" alt="search" width={13} height={13} />
                  <input
                    type="text"
                    className=" w-full text-[13px] leading-6 px-2 py-1 bg-transparent outline-none text-white placeholder:text-[#7A7A8E]"
                    placeholder="Search"
                    value={searchValue}
                    onChange={(e) => {
                      setRuneOffset(0);
                      setSearchValue(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className=" w-full flex flex-col  gap-4">
                <div className=" flex w-full justify-between px-6 xl:px-10 text-[12px] leading-5 uppercase text-[#7A7A8E]">
                  <p className=" font-monsterate">
                    {exploreData?.exploreText?.runeName}
                  </p>
                  <p className=" font-monsterate">
                    {exploreData?.exploreText?.price}
                  </p>
                </div>

                <div
                  ref={cardRef}
                  className=" w-full  h-[calc(100vh-100px)] xs:h-[calc(100vh-460x)] overflow-scroll flex flex-col gap-[px]"
                >
                  <div
                    className={`bg-black/40 h-full ${
                      runeApiFetching ? "flex" : "hidden"
                    } left-0 backdrop-blur-[2px] top-0 absolute flex w-full items-center justify-center`}
                  >
                    {/* <Load /> */}
                    <PiSpinner className=" animate-spin text-white text-4xl" />
                  </div>
                  {runeData &&
                    runeData.map((item: Token, i: number) => (
                      <Card key={i} item={item} />
                    ))}
                  <div className=" pointer-events-none absolute z-10 bg-black/60 w-[98%] -bottom-[150px] sm:bottom-0 h-[80px] backdrop-blur-[3px]  xs:h-[100px]"></div>
                </div>
              </div>
            </div>
          ) : null}

          {step === 2 && (
            <div className=" xl:px-3 flex flex-col gap-6 h-[calc(100vh-270px)]">
              <div className=" w-[85%] h-[50px] px-3 py-1 mx-auto border-2 gradient-border  flex items-center rounded-xl">
                <img src="/search.svg" alt="search" width={13} height={13} />
                <input
                  type="text"
                  className=" w-full text-[13px] leading-6 px-2 py-1 bg-transparent outline-none text-white placeholder:text-[#7A7A8E]"
                  placeholder="Search"
                  value={runeSearchValue}
                  onChange={(e) => {
                    setRuneSearchValue(e.target.value);
                  }}
                />
              </div>
              <div
                ref={myRuneRef}
                className="w-full   h-[calc(100vh-100px)] xs:h-[calc(100vh-450x)] overflow-y-scroll flex flex-col gap-[px]"
              >
                <div
                  className={`bg-black/40 h-full ${
                    myRuneApiFetching ? "flex" : "hidden"
                  } left-0 backdrop-blur-[2px] top-0 absolute flex w-full items-center justify-center`}
                >
                  <PiSpinner className="animate-spin text-white text-4xl" />
                </div>
                {walletAddress.length === 0 || myRunesData.length === 0 ? (
                  <div className="flex flex-col items-center justify-center text-white  h-full">
                    {exploreText?.noData}
                  </div>
                ) : (
                  myRunesData.map((item: any, i: number) => (
                    <MyRunes key={i} item={item} />
                  ))
                )}
                {/* <div className=" flex justify-between items-center w-full px-6 mt-6 ">
                  <p className="text-[15px] font-monsterate font-semibold leading-[27px] text-[#7A7A8E]">
                    Total Value
                  </p>
                  <p className="text-[15px] font-monsterate font-semibold leading-[27px] text-[#7A7A8E]">
                    -
                  </p>
                </div> */}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
