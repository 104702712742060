import React, { useContext } from "react";
import { CmsContext } from "../../../../../context/CmsContext";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedToken } from "../../../../../services/GlobalSlice";
import { formatNumberToMillion } from "../../../../../utils/common";

const MyRunes: React.FC<any> = ({ item }: any) => {
  const contextData: any = useContext(CmsContext);

  const selectedToken = useSelector(
    (state: any) => state.global.selectedToken
  );
  const dispatch = useDispatch();

  return (
    <div
      onClick={() => {
        dispatch(setSelectedToken(item));
      }}
      className="group cursor-pointer px-[5px] py-1 border-l-4 hover:border-[#FFE564] transition-all border-transparent hover:bg-[#0F0F14] bg-transparent"
    >
      <div className=" border-b-[1px] hover:border-transparent pb-3 flex flex-col gap-2 border-[#202026]">
        <div className=" flex justify-between ">
          <div className=" flex flex-col gap-1">
            <p className=" text-[21px] font-monsterate font-semibold leading-[27px] text-white ">
              {item?.etching?.runeName.substring(0, 3)}
            </p>
            <p className=" text-[13px] font-monsterate tracking-[-0.13px] font-medium leading-[23px] text-[#7A7A8E] ">
              {item?.etching?.runeName}
            </p>
          </div>
          <div className=" flex flex-col items-end">
            <p className="text-[15px] font-monsterate font-bold leading-[27px] text-white">
              {item?.etching?.divisibility
                ? item?.runebalance
                  ? item?.runebalance / 10 ** item?.etching?.divisibility
                  : "-"
                : item?.runebalance
                ? item?.runebalance
                : "-"}
            </p>
            <span className="text-[12px] leading-[21px] text-[#7A7A8E]">
              {item?.etching && item?.unitPriceSats
                ? item.runebalance &&
                  `$${(
                    ((item.runebalance /
                      10 ** (item?.etching?.divisibility || 0)) *
                      contextData?.btcValue *
                      item?.unitPriceSats) /
                    10 ** 8
                  ).toFixed(2)}`
                : "-"}
            </span>
          </div>
        </div>
        <div className=" w-full  flex justify-between items-center gap-3">
          {item?.imageURI ? (
            <img
              src={item?.imageURI ? item?.imageURI : "/question.svg"}
              alt="symbol"
              className=" w-10 h-10 rounded-full"
            />
          ) : item?.etching?.symbol ? (
            <div className="w-10 h-10 rounded-full  flex items-center justify-center">
              <span>{item?.etching?.symbol}</span>
            </div>
          ) : (
            <img
              src="/question.svg"
              alt="symbol"
              className=" w-10 h-10 rounded-full"
            />
          )}
          <div
            className={` w-full flex px-2 py-2  justify-between rounded-lg 
          ${item?.unitPriceChange < 0 ? "bg-[#A92B68]/20" : "bg-[#2BBE49]/10"}`}
          >
            <div className=" flex items-center gap-3">
              <p
                className={` text-[15px] px-1 font-monsterate text-nowrap font-semibold leading-[27px] text-white`}
              >
                {`${
                  item?.unitPriceSats
                    ? formatNumberToMillion(item?.unitPriceSats) + " Sats"
                    : "-"
                }`}
              </p>
              <p
                className={` text-[15px] px-1 font-monsterate font-semibold leading-[27px] text-[#7A7A8E]`}
              >
                {item?.unitPriceSats
                  ? `$${(
                      (item?.unitPriceSats * contextData?.btcValue) /
                      10 ** 8
                    )?.toFixed(2)}`
                  : "-"}
              </p>
            </div>
            <div className=" flex items-center gap-1">
              <p
                className={` text-[15px] font-monsterate font-semibold leading-[27px] ${
                  item?.unitPriceChange < 0
                    ? "text-[#EF348E] shadow-md"
                    : "text-green-500 shadow-md"
                } `}
              >
                {item?.unitPriceChange?.toFixed(2)}%
              </p>
              {item?.unitPriceChange >= 0 ? (
                <img src="/up.svg" alt="arrow" width={14} height={10} />
              ) : (
                <img src="/down.svg" alt="arrow" width={14} height={10} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyRunes;
