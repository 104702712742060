import React from "react";
import { cmsText } from "../../utils/types";

type Props = {
  onConnect: React.MouseEventHandler<HTMLButtonElement>;
  cmsData: cmsText | null | undefined;
};

const Banner = ({ onConnect, cmsData }: Props) => {
  return (
    <>
      <div className="w-full mt-52 xs:mt-40 lg:mt-[130px] sm:w-[80%] lg:w-1/2 h-full px-6 flex flex-col justify-center items-center">
        <img
          className="w-[300px] sm:w-[400px]"
          src={"/BannerMint.svg"}
          alt="image1"
        />
        <img
          className="mt-2 sm:mt-[-35px] w-[300px] sm:w-[400px]"
          src={"/BannerRunes.svg"}
          alt="image2"
        />
        <h1 className="text-[#FFE564] font-bold text-lg sm:text-2xl mt-5">
          {cmsData?.heading}
        </h1>
        {/* <button
          type="button"
          className=" font-bold rounded-xl text-nowrap mt-5 sm:mt-8 px-[4.5rem] py-3 text-black border-with-gradient"
          onClick={onConnect}
        >
          {cmsData?.connectText}
        </button> */}
      </div>
    </>
  );
};

export default Banner;
