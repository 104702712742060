import { formatNumberToMillion } from "../../../../utils/common";

const CardDetails = ({ token, exploreText, btcValue }: any) => {
  return (
    <div className=" flex ">
      <div className=" w-full flex flex-row  gap-3 mb-3">
        <div className=" w-[84px] h-[84px] rounded-full ">
          {token?.imageURI ? (
            <img
              src={token?.imageURI ? token?.imageURI : "/question.svg"}
              alt="dog"
              className={`w-[84px] h-[84px] mt-3 rounded-full ${
                !token?.imageURI && "text-white"
              }`}
            />
          ) : token?.etching?.symbol ? (
            <div className="w-[84px] h-[84px] mt-3 rounded-full flex items-center justify-center border-[1px] border-[#202026]">
                <span className="text-[2rem] leading-none flex items-center justify-center w-full h-full">
                {token?.etching?.symbol}</span>
            </div>
          ) : (
            <img
              src="/question.svg"
              alt="symbol"
              className=" w-[84px] h-[84px] mt-3 rounded-full"
            />
          )}
        </div>
        <div className=" text-white flex flex-col gap-2">
          <div>
            <div className=" flex gap-4  items-center">
              <p className=" text-lg md:text-[32px] font-monsterate leading-normal md:leading-[57px] font-semibold ">
                {token?.etching?.runeName}
              </p>
            </div>

            <div className=" flex gap-4 items-center font-bold">
              <p className=" text-[#7A7A8E] font-monsterate text-[13px] leading-[20px]">
                {exploreText?.rune} #{token?.etching?.runeNumber}
              </p>
              <span className="text-[#7A7A8E] font-monsterate text-[13px] leading-[20px]">
                |
              </span>
              <p className=" text-[#7A7A8E] font-monsterate text-[13px] leading-[20px]">
                ID:{" "}
                {token?.etching?.runeId?.block
                  ? token?.etching?.runeId?.block
                  : "-"}
                :{token?.etching?.runeId?.tx ? token?.etching?.runeId?.tx : "-"}
              </p>
              <span className="">
                {token?.etching?.symbol && token?.etching?.symbol}
              </span>
            </div>

            {/* <div className=" flex gap-5 items-center">
              <p className=" text-[#7A7A8E] text-[15px] leading-[27px]">
                {exploreText?.supply}
              </p>
              <p className=" text-[15px] leading-[27px]">
                {token?.etching?.premine ? token?.etching?.premine : "-"}
                <span className=" ml-2">{token?.etching?.symbol}</span>
              </p>
            </div> */}
          </div>

          <div className=" flex  items-center gap-6 flex-col smx:flex-row ">
            <div className=" w-[90%] smx:w-auto  flex gap-6 smx:gap-5">
              <div>
                <p className=" text-[#7A7A8E] font-monsterate text-nowrap text-[15px] leading-6">
                  {exploreText?.allVolume}
                </p>
                <p
                  title={
                    token?.totalVol && btcValue && token?.totalVol * btcValue
                  }
                  className=" text-[18px] sm:text-[20px] leading-7 sm:leading-[32px] text-center  font-monsterate "
                >
                  {token?.totalVol && btcValue
                    ? `$${formatNumberToMillion(token?.totalVol * btcValue)}`
                    : "-"}
                </p>
              </div>
              <div>
                <p className=" text-[#7A7A8E] font-monsterate text-nowrap text-[15px] leading-6 ">
                  {exploreText?.Volume24hr}
                </p>
                <p
                  title={token?.vol && btcValue && token?.vol * btcValue}
                  className="text-[18px] sm:text-[20px] leading-7 sm:leading-[32px]  text-center    font-monsterate  "
                >
                  {token?.vol && btcValue
                    ? `$${formatNumberToMillion(token?.vol * btcValue)}`
                    : "-"}
                </p>
              </div>
            </div>
            <div className=" w-[90%] smx:w-auto  flex gap-4 smx:gap-5">
              <div>
                <p className=" text-[#7A7A8E] font-monsterate text-nowrap text-[15px] leading-6">
                  {exploreText?.marketCap}
                </p>
                <p
                  title={
                    token?.marketCap && btcValue && token?.marketCap * btcValue
                  }
                  className="text-[18px] sm:text-[20px] leading-7 sm:leading-[32px] text-center   font-monsterate "
                >
                  {token?.marketCap && btcValue
                    ? `$${formatNumberToMillion(token?.marketCap * btcValue)}`
                    : "-"}
                </p>
              </div>
              <div>
                <p className=" text-[#7A7A8E] font-monsterate text-nowrap text-[15px] leading-6">
                  {exploreText?.holders}
                </p>
                <p
                  title={token?.holderCount && token?.holderCount}
                  className="text-[18px] sm:text-[20px] leading-7 sm:leading-[32px]  text-center  font-monsterate "
                >{`${
                  token?.holderCount
                    ? formatNumberToMillion(token?.holderCount)
                    : "-"
                }`}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardDetails;
