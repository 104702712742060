import { useContext } from "react";
import Wallet, {
  Address,
  AddressPurpose,
} from "sats-connect";
import { useLocalStorage } from "../../hooks";
import Banner from "../Banner/Banner";
import DisplayTokens from "../DisplayTokens/DisplayTokens";
import { CmsContext } from "../../context/CmsContext";

const Home = () => {

  const cmsData = useContext(CmsContext);
  const [addressInfo, setAddressInfo] = useLocalStorage<Address[]>(
    "addresses",
    []
  );

  const onConnect = async () => {
    const response = await Wallet.request("getAccounts", {
      purposes: [
        AddressPurpose.Payment,
        AddressPurpose.Ordinals,
        AddressPurpose.Stacks,
      ],
      message: "Cool app wants to know your addresses!",
    });
    if (response.status === "success") {
      setAddressInfo(response.result);
    }
  };

  return (
    <div className=" mb-[150px]  flex flex-col justify-center overflow-y-scroll items-center">
      <Banner cmsData={cmsData?.cmsText} onConnect={onConnect} />
      <DisplayTokens cmsData={cmsData?.cmsText} />
    </div>
  );
};

export default Home;
