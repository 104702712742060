import { HTMLAttributes } from "react";
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import { Outlet } from "react-router-dom";

const MainContainer = ({
  children,
  className,
}: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={["min-h-[calc(100vh-270px)]", className || " "].join(" ")}>
      {children}
    </div>
  );
};

const BaseLayout = () => {

  return (
    <>
      <div className=" flex flex-col items-center">
        <Navbar />
        <MainContainer>
          <Outlet />
        </MainContainer>
        <Footer />
      </div>
    </>
  );
};

export default BaseLayout;
