import React, { useEffect, useRef, useState } from "react";
import datafeed from "../../../../utils/datafeed";
import {
  widget,
  ResolutionString,
  TimeFrameValue,
} from "../../../../charting_library";

const TradingView = ({token}: any) => {
  const chartContainerRef =
    useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;

  const [defaultProps, setDefaultProps] = useState({
    // symbol: `${inputTok?.data?.address}/${outputTok?.data?.address}/${outputTok?.data?.isMetaplex}`,
    // symbol: `${JSON.stringify(chartInputState)}::${JSON.stringify(chartOutputState)}`,
    symbol: `${JSON.stringify(token)}`,
    interval: "60",
    // datafeedUrl: 'https://demo_feed.tradingview.com',
    libraryPath: "/charting_library/",
    chartsStorageUrl: "https://saveload.tradingview.com",
    chartsStorageApiVersion: "1.1",
    clientId: "tradingview.com",
    userId: "public_user_id",
    fullscreen: false,
    autosize: true,
    studiesOverrides: {
      //   "volume.volume.color.0": "#FFC34E",
      //   "volume.volume.color.1": "#1029F9",
    },
    load_last_chart: true,
  });

  // console.log(token,"token")

    useEffect(() => {
      setDefaultProps((prevProps) => ({
        ...prevProps,
        symbol: `${JSON.stringify(token)}`,
      }));
    }, [token]);
    
    

  // const chartInputState = useSelector((state) => state.global.chartInputState);
  // const chartOutputState = useSelector((state) => state.global.chartOutputState);
  // console.log(token?.rune,"name")

  // const defaultProps = {
  //   // symbol: `${inputTok?.data?.address}/${outputTok?.data?.address}/${outputTok?.data?.isMetaplex}`,
  //   // symbol: `${JSON.stringify(chartInputState)}::${JSON.stringify(chartOutputState)}`,
  //   symbol: `${token?.rune}`,
  //   interval: "60",
  //   // datafeedUrl: 'https://demo_feed.tradingview.com',
  //   libraryPath: "/charting_library/",
  //   chartsStorageUrl: "https://saveload.tradingview.com",
  //   chartsStorageApiVersion: "1.1",
  //   clientId: "tradingview.com",
  //   userId: "public_user_id",
  //   fullscreen: false,
  //   autosize: true,
  //   studiesOverrides: {
  //     //   "volume.volume.color.0": "#FFC34E",
  //     //   "volume.volume.color.1": "#1029F9",
  //   },
  //   load_last_chart: true,
  // };

  useEffect(() => {
    const savedResolution = localStorage.getItem(
      "chart.lastUsedTimeBasedResolution"
    ) || "30";
    const savedTimeframe =
      localStorage.getItem("chart.lastUsedTimeFrame") || "1M";
    let savedIndicators: any;
    try {
      savedIndicators = JSON.parse(
        localStorage.getItem("chart.indicators") || "[]"
      );
    } catch (e) {
      console.error("Error parsing saved indicators:", e);
    }

    const widgetOptions: any = {
      symbol: defaultProps?.symbol,
      // BEWARE: no trailing slash is echartInputStatexpected in feed URL
      // datafeed: new window.Datafeeds.UDFCompatibleDatafeed(defaultProps.datafeedUrl),
      datafeed: datafeed as any,
      interval: savedResolution ? savedResolution : defaultProps.interval,
      timeframe: savedTimeframe || "1M",
      container: chartContainerRef.current,
      library_path: defaultProps.libraryPath,
      locale: "en",
      disabled_features: [
        "symbol_search_hot_key",
        "header_symbol_search",
        "header_compare",
        "header_quick_search",
        "edit_buttons_in_legend",
        "legend_context_menu",
        "popup_hints",
      ],
      enabled_features: ["seconds_resolution"],
      // legend_widget
      // charts_storage_url: defaultProps.chartsStorageUrl,
      // charts_storage_api_version: defaultProps.chartsStorageApiVersion,
      // client_id: defaultProps.clientId,
      // user_id: defaultProps.userId,
      fullscreen: defaultProps.fullscreen,
      autosize: defaultProps.autosize,
      studies_overrides: defaultProps.studiesOverrides,
      theme: "dark",
      // toolbar_bg: "#1F1F1D",
      loading_screen: { foregroundColor: "#EABC8F" },
      custom_formatters: {
        priceFormatterFactory: (symbolInfo: any) => {
          // if (symbolInfo === null) {
          //   return null;
          // }
          // console.log(symbolInfo, "syblinfo")
          // if (symbolInfo.format === 'volume') {
          //     return {
          //         format: (price, signPositive) => {
          //             if (price >= 1000000000) {
          //                 return `${(price / 1000000000).toFixed(3)}B`;
          //             }
          //             if (price >= 1000000) {
          //                 return `${(price / 1000000).toFixed(3)}M`;
          //             }
          //             if (price >= 1000) {
          //                 return `${(price / 1000).toFixed(3)}K`;
          //             }
          //             return price.toFixed(2);
          //         },
          //     };
          // }
          // return null; // The default formatter will be used.
        },
      },
      settings_adapter: {
        initialSettings: {
          resolution: savedResolution || "30",
          timeframe: savedTimeframe || "1D",
        },
        setValue: function (key: any, value: any) {
          // console.log(`set value: ${key} ${value}`);
          localStorage.setItem(key, value);
        },
        removeValue: function (key: any) {
          // console.log(`remove value: ${key}`);
          localStorage.removeItem(key);
        },

        errorHandler: function (error: any) {
          console.error("Error occurred:", error);
        },
      },
      overrides: {
        // "paneProperties.backgroundGradientStartColor": "#000",
        // "paneProperties.backgroundType": "solid",
        // "paneProperties.background": "#000",
        // "paneProperties.background": "#000",
        // "paneProperties.separatorColor": "#fff",
        "paneProperties.backgroundGradientEndColor": "#000",
        // "paneProperties.backgroundGradientStartColor": "#1F1F1D",
        "mainSeriesProperties.barStyle.upColor": "#FFFFFF",
        // "paneProperties.separatorColor": "#9598a1",
        // "scalesProperties.axisHighlightColor": "#000",
        // "scalesProperties.textColor": "#fff"
        //heikin-ashi
        // "mainSeriesProperties.haStyle.upColor": "#FFC34E",
        // "mainSeriesProperties.haStyle.downColor": "#1029F9",
        "mainSeriesProperties.haStyle.drawWick": true,
        "mainSeriesProperties.haStyle.drawBorder": true,
        "mainSeriesProperties.haStyle.borderColor": "",
        // "mainSeriesProperties.haStyle.borderUpColor": "#FFC34E",
        // "mainSeriesProperties.haStyle.borderDownColor": "#1029F9",
        // "mainSeriesProperties.haStyle.wickUpColor": "#FFC34E",
        // "mainSeriesProperties.haStyle.wickDownColor": "#1029F9",
        "mainSeriesProperties.haStyle.barColorsOnPrevClose": false,
        //baseline
        // "mainSeriesProperties.baselineStyle.baselineColor": "#FFC34E",
        // "mainSeriesProperties.baselineStyle.topFillColor1": "#FFC34E",
        "mainSeriesProperties.baselineStyle.topFillColor2":
          "rgba( 78, 205, 196, 0.1)",
        // "mainSeriesProperties.baselineStyle.bottomFillColor1": "#1029F9",
        "mainSeriesProperties.baselineStyle.bottomFillColor2":
          "rgba( 205, 78, 87, 0.1)",
        // "mainSeriesProperties.baselineStyle.topLineColor": "#FFC34E",
        // "mainSeriesProperties.baselineStyle.bottomLineColor": "#1029F9",
      },
      // localStorage:
    };

    const tvWidget = new widget(widgetOptions);

    tvWidget.onChartReady(() => {
      tvWidget.activeChart().resolution();
      tvWidget.activeChart().setResolution(savedResolution as any);
      tvWidget.activeChart().setTimeFrame(savedTimeframe as any);
      tvWidget
        .activeChart()
        .onIntervalChanged()
        .subscribe(
          null,
          (interval: any, timeFrameParameters: { timeframe?: any }) => {
            localStorage.setItem("chart.lastUsedTimeBasedResolution", interval || "30");
            localStorage.setItem(
              "chart.lastUsedTimeFrame",
              timeFrameParameters?.timeframe?.value || "1M"
            );
            // console.log(`Resolution changed to: ${interval}, timeFrame, ${timeframeObj.timeframe?.value}`);
          }
        );

      savedIndicators.forEach((indicator: any) => {
        if (indicator.name !== "Volume") {
          return tvWidget
            .activeChart()
            .createStudy(indicator.name, false, false, indicator?.options);
        }
      });

      const saveIndicatorsToLocalStorage = (indicators: any) => {
        localStorage.setItem("chart.indicators", JSON.stringify(indicators));
      };

      tvWidget.subscribe("onAutoSaveNeeded", () => {
        savedIndicators?.length === 1 &&
          !savedIndicators.find(
            (study: any) => study.name === "Moving Average"
          ) &&
          savedIndicators.find((study: any) => study.name === "Volume") &&
          tvWidget
            .activeChart()
            .createStudy("Moving Average", false, false, { length: 26 });
        savedIndicators?.length === 1 &&
          !savedIndicators.find(
            (study: any) => study.name === "Relative Strength Index"
          ) &&
          savedIndicators.find((study: any) => study.name === "Volume") &&
          tvWidget
            .activeChart()
            .createStudy("Relative Strength Index", false, false);

        const currentStudies = tvWidget.activeChart().getAllStudies();
        const currentIndicators = currentStudies?.map((study) => ({
          name: study.name,
        }));
        if (
          JSON.stringify(currentIndicators) !==
          JSON?.stringify(savedIndicators as any)
        ) {
          savedIndicators = currentIndicators;
          saveIndicatorsToLocalStorage(savedIndicators);
        }
      });

      tvWidget.chart().getSeries().setChartStyleProperties(1, {
        // upColor: "#FFC34E",
        // downColor: "#1029F9",
        // borderUpColor: "#FFC34E",
        // borderDownColor: "#1029F9",
        // wickUpColor: "#FFC34E",
        // wickDownColor: "#1029F9",
      });
      tvWidget.chart().getSeries().setChartStyleProperties(0, {
        // upColor: "#FFC34E",
        // downColor: "#1029F9",
        // borderUpColor: "#FFC34E",
        // borderDownColor: "#1029F9",
        // wickUpColor: "#FFC34E",
        // wickDownColor: "#1029F9",
      });
      tvWidget.chart().getSeries().setChartStyleProperties(2, {
        // upColor: "#FFC34E",
        // downColor: "#1029F9",
        // borderUpColor: "#FFC34E",
        // borderDownColor: "#1029F9",
        // wickUpColor: "#FFC34E",
        // wickDownColor: "#1029F9",
      });
      tvWidget.chart().getSeries().setChartStyleProperties(9, {
        // upColor: "#FFC34E",
        // downColor: "#1029F9",
        // borderUpColor: "#FFC34E",
        // borderDownColor: "#1029F9",
        // wickUpColor: "#FFC34E",
        // wickDownColor: "#1029F9",
      });
      tvWidget.chart().getSeries().setChartStyleProperties(13, {
        barColorsOnPrevClose: true,
        // priceSource: "close",
        // upColor: "#FFC34E",
        // downColor: "#1029F9",
        // borderUpColor: '#FFC34E',
        // borderDownColor: '#1029F9',
        // wickUpColor: '#FFC34E',
        // wickDownColor: '#1029F9'
      });
      tvWidget.chart().getSeries().setChartStyleProperties(16, {
        // upColor: "#FFC34E",
        // downColor: "#1029F9",
        // borderUpColor: "#FFC34E",
        // borderDownColor: "#1029F9",
        // wickUpColor: "#FFC34E",
        // wickDownColor: "#1029F9",
      });
    });

    return () => {
      tvWidget.remove();
    };
  });
  return (
    <div
      ref={chartContainerRef}
      className={"TVChartContainer h-[492px] md:h-full "}
    />
  );
};

export default TradingView;
