import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Address } from "sats-connect";
import { Token } from "../utils/types";

interface GlobalState {
  tokens?: any;
  myRunes?: any; 
  walletAddress?: Address[]; 
  points?: any;
  selectedToken?: Token | null | undefined;
  runePoints: number;
  chartInitial?: Token | null | undefined;
  pointsIncrement?: boolean | undefined;
}

const initialState: GlobalState = {
  // selectedToken: {},
  runePoints: 0,
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setRunesData: (state, action: PayloadAction<any>) => {
      state.tokens = action.payload;
    },
    setMyRunesData: (state, action: PayloadAction<any>) => {
      state.myRunes = action.payload;
    },
    setWalletKaAddress: (state, action: PayloadAction<Address[]>) => {
      state.walletAddress = action.payload;
    },
    setPointsData: (state, action: PayloadAction<any>) => {
      state.points = action.payload;
    },
    setSelectedToken: (state, action: PayloadAction<any>) => {
      state.selectedToken = action.payload;
    },
    setRunePoints: (state, action: PayloadAction<any>) => {
      state.runePoints = action.payload;
    },
    setChartInitial: (state, action: PayloadAction<any>) => {
      state.chartInitial = action.payload;
    },
    setPointsIncrement: (state, action: PayloadAction<boolean>) => {
      state.pointsIncrement = action.payload;
    }
  },
});

export const {
  setRunesData,
  setMyRunesData,
  setWalletKaAddress,
  setPointsData,
  setSelectedToken,
  setRunePoints,
  setChartInitial,
  setPointsIncrement
} = globalSlice.actions;

export default globalSlice.reducer;
