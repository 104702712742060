import React, { createContext, useEffect, useState, ReactNode } from "react";
import { ExploreText, cmsText, PointsText } from "../utils/types";
import { getBtcValue } from "../utils/helpers";
import { useLocalStorage } from "../hooks";
import { Address, BitcoinNetworkType } from "sats-connect";
import { useDispatch } from "react-redux";
import { setWalletKaAddress } from "../services/GlobalSlice";

// Define the context type
interface CmsContextType {
  cmsText: cmsText |  undefined | null;
  exploreText: ExploreText | null | undefined;
  pointsText: PointsText | undefined | null;
  btcValue: number | undefined | null;
  walletAddress: Address[];
  setWalletAddress: any;
  addressInfo: any;
  setAddressInfo: any;
  network: any;
  setNetwork: any;
}

export const CmsContext = createContext<CmsContextType | null>(null);

const CmsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [cmsText, setCMSText] = useState<cmsText | null>(null);
  const [exploreText, setExploreText] = useState<ExploreText | null>(null);
  const [pointsText, setPointsText] = useState<PointsText | null>(null);
  const [btcValue, setBtcValue] = useState<number | null>();

  const dispatch = useDispatch();

  // for wallet address
  const [network, setNetwork] = useLocalStorage<BitcoinNetworkType>(
    "network",
    BitcoinNetworkType.Mainnet
  );
  const [addressInfo, setAddressInfo] = useLocalStorage<Address[]>(
    "addresses",
    []
  );

  const [walletAddress, setWalletAddress] = useState<Address[]>([]);

  // const isConnected = addressInfo.length > 0;
  useEffect(() => {
    if (addressInfo) {
      setWalletAddress(
        addressInfo?.filter(
          (item) => String(item.purpose).toLowerCase() === "ordinals"
        )
      );
      dispatch(
        setWalletKaAddress(
          addressInfo?.filter(
            (item) => String(item.purpose).toLowerCase() === "ordinals"
          )
        )
      );
    }
    setNetwork(BitcoinNetworkType.Mainnet);
  }, [addressInfo]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_CMS_BASE_URL + "/home"
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setCMSText(data?.data?.attributes);
      } catch (error) {
        console.error("Error fetching CMS data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchExploreData = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_CMS_BASE_URL + "/explore"
        );
        if (!response.ok) {
          throw new Error("Network response was not ok for explore cms");
        }
        const data = await response.json();
        setExploreText(data?.data?.attributes);
      } catch (error) {
        console.error("Error fetching explore data:", error);
      }
    };
    fetchExploreData();
  }, []);

  useEffect(() => {
    const fetchPointsData = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_CMS_BASE_URL + "/point"
        );
        if (!response.ok) {
          throw new Error("Network response was not ok for points cms");
        }
        const data = await response.json();
        setPointsText(data?.data?.attributes);
      } catch (error) {
        console.error("Error fetching points data:", error);
        // Handle the error
      }
    };
    fetchPointsData();
  }, []);

  useEffect(() => {
    const getValue = async () => {
      try {
        const val = await getBtcValue();
        setBtcValue(Number(val[0]?.price_usd));
      } catch (err) {
        console.log(err);
      }
    };
    getValue();
  }, []);

  return (
    <CmsContext.Provider
      value={{
        cmsText,
        exploreText,
        pointsText,
        btcValue,
        walletAddress,
        setWalletAddress,
        addressInfo,
        setAddressInfo,
        network,
        setNetwork
      }}
    >
      {children}
    </CmsContext.Provider>
  );
};

export default CmsProvider;
